import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MockFlowService {

  constructor() {

  }

  setData(data: any) {
    for (var k in data) {
      if (data.hasOwnProperty(k)) {
        localStorage.setItem(k, data[k]);
      }
    }
  }

  getData(key: string) {
    return localStorage.getItem(key)
  }

  setId(id: any){
    localStorage.setItem('id', id);
  }

  getId(){
    return localStorage.getItem('id')
  }

  removeData(){
    localStorage.removeItem('hash');
    localStorage.removeItem('token_enterprise');
    localStorage.removeItem('token_user');
    localStorage.removeItem('prospect_id');
    localStorage.removeItem('finish_img');
    localStorage.removeItem('document');
    localStorage.removeItem('type_document_id');
    localStorage.removeItem('paths');
  }

  deleteItemData(key: string) {
    localStorage.removeItem(key);
  }

  savePaths(d: any) {
    const defaultPaths: any = {
      'formulario_cliente':'/registro',
      'validacion_telefonica':'/validacion-telefonica',
      'validacion_correo':'/validacion-correo-electronico',
      'lista_productos':'/producto/listado',
      'simulacion_credito':'/producto/simulador',
      'documentos_solicitud':'/documentos-solicitud',
      'documentos_credito':'/documentos-credito',
    }

    let paths: any = {};
    let current_step =  0;
    let number_steps = d.length;
    if (d.findIndex((p: any) => p.name == "/documentos_credito") === -1) number_steps++;
    d.forEach((value: any, key: any) => {

      if(value.name != 'vista_inicial') {
        current_step++;
        const path = defaultPaths[value.name];
        const hash = this.getData('hash');

        let prev_path = key == 1 ? `/nuevo-credito/inicio/${hash}`: `/nuevo-credito${defaultPaths[d[Object.keys(d)[key - 1]]['name']]}`;
        let next_path = key == d.length - 1 ?  '/nuevo-credito/finalizacion' : `/nuevo-credito${defaultPaths[d[Object.keys(d)[key + 1]]['name']]}`;
        if(path == '/producto/simulador' || path =='/producto/listado') {
          if (Object.keys(paths).indexOf('/producto') === -1) {
            paths['/producto'] = { path: '/nuevo-credito/producto', backward_url: prev_path, forward_url: '/', content_data: {}, current_step: current_step, total_step: number_steps };
          }
        }
        if(path == '/producto/simulador') {
          // next_path = next_path == '/nuevo-credito/producto/listado' ? `/nuevo-credito${defaultPaths[d[Object.keys(d)[key + 2]]['name']]}` : next_path;
          paths['/producto/simulador/resultados'] = { path: '/nuevo-credito/producto/simulador/resultados', backward_url: '/nuevo-credito/producto/simulador', forward_url: next_path, content_data: {}, current_step: key, total_step: number_steps };
          next_path = '/';
          paths['/producto/simulador/resultados/proyeccion'] = { path: '/nuevo-credito/producto/simulador/proyeccion', backward_url: '/nuevo-credito/producto/simulador', forward_url: '/', content_data: {}, current_step: key, total_step: number_steps };
          next_path = next_path == '/nuevo-credito/producto/listado' ? `/nuevo-credito${defaultPaths[d[Object.keys(d)[key + 2]]['name']]}` : next_path;
          paths['/producto/simulador/otros-parametros'] = { path: '/nuevo-credito/producto/simulador/otros-parametros', backward_url: '/nuevo-credito/producto/simulador', forward_url: next_path, content_data: {}, current_step: key, total_step: number_steps };
          prev_path = '/nuevo-credito/producto';
          next_path = '/nuevo-credito/producto/simulador/resultados';
        } else if(path == '/producto/listado') {
          next_path = next_path == '/nuevo-credito/producto/simulador' ? `/nuevo-credito${defaultPaths[d[Object.keys(d)[key + 2]]['name']]}` : next_path;
          paths['/producto/listado/detalle'] = { path: '/nuevo-credito/producto/listado/detalle', backward_url: '/nuevo-credito/producto/listado', forward_url: next_path, content_data: {}, current_step: key, total_step: number_steps };
          prev_path = '/nuevo-credito/producto'
          next_path = '/';
        } else if(path == '/documentos-solicitud') {
          paths['/documentos-solicitud/subir-documento'] = { path: '/nuevo-credito/documentos-solicitud/subir-documento', backward_url: '/nuevo-credito/documentos-solicitud', forward_url: '/', content_data: {}, current_step: key, total_step: number_steps};
        } else if(path == '/documentos-credito') {
          paths['/documentos-credito/leer-documento'] = { path: '/nuevo-credito/documentos-credito/leer-documento', backward_url: '/nuevo-credito/documentos-credito', forward_url: '/', content_data: {}, current_step: key, total_step: number_steps };
          const current_step_special = current_step + 1;
          paths['/firmar-documentos'] = { path: '/nuevo-credito/firmar-documentos', backward_url: '/nuevo-credito/documentos-credito', forward_url: next_path, content_data: {}, current_step: current_step_special, total_step: number_steps };
          next_path = '/nuevo-credito/firmar-documentos';
        }

        prev_path = prev_path == '/nuevo-credito/producto/simulador' || prev_path == '/nuevo-credito/producto/listado' ? '/nuevo-credito/producto' : prev_path;
        next_path = next_path == '/nuevo-credito/producto/simulador' || next_path == '/nuevo-credito/producto/listado' ? '/nuevo-credito/producto' : next_path;
        paths[path] = { path: path, backward_url: prev_path, forward_url: next_path, content_data: {}, current_step: current_step, total_step: number_steps };

        if( key == d.length - 1) {
          prev_path = defaultPaths[d[Object.keys(d)[d.length - 1]]['name']] == '/documentos-credito' ? '/nuevo-credito/firmar-documentos' : `/nuevo-credito${defaultPaths[d[Object.keys(d)[d.length - 1]]['name']]}`;
          paths['/finalizacion'] = { path: '/nuevo-credito/finalizacion', backward_url: prev_path, forward_url: '/', content_data: {}, current_step: number_steps, total_step: number_steps };
        }
      }
    })
    this.setData({paths: JSON.stringify(paths)});

  }

  searchPath(path: string) {
    let validate = false;
    const paths: any = JSON.parse(this.getData('paths') || '[]');
    const objKeys = Object.keys(paths);

    if (objKeys.indexOf(path) !== -1) {
      validate = true;
    }
    return validate;
  }

  clearSession(){
    localStorage.clear();
  }

  isAuthenticated():boolean {
    return localStorage.getItem('token') ? true : false;
  }

  //* Metodos documentos
  createDocuments(){
    const documents: Document[] = [
      {
        id: 1,
        title: 'Soporte de ingresos',
        link: 'nuevo-credito/documentos-solicitud/subir-documento/',
        type: 'link',
        url: ''
      },
      {
        id: 2,
        title: 'Soporte servicios públicos',
        link: 'nuevo-credito/documentos-solicitud/subir-documento/',
        type: 'link',
        url: ''
      },
      {
        id: 3,
        title: 'Soporte de ingresos',
        link: 'nuevo-credito/documentos-solicitud/subir-documento/',
        type: 'link',
        url: ''
      },
      {
        id: 4,
        title: 'Carta laboral',
        link: 'nuevo-credito/documentos-solicitud/subir-documento/',
        type: 'link',
        url: ''
      }
    ];

    localStorage.setItem('documents', JSON.stringify(documents) );
  }

  getDocuments():Document[] {
    return JSON.parse( localStorage.getItem('documents') || '[]');
  }

  markDocumentAsCheck(id: number){
    const documents = this.getDocuments();

    const newDocuments = documents.map( el => el.id == id ? {...el, type: 'check', url: `https://drive.google.com/${el.id}`} : el );

    localStorage.setItem('documents', JSON.stringify(newDocuments) );
  }

  markDocumentAsNotCheck(id: number){
    const documents = this.getDocuments();

    const newDocuments = documents.map( el => el.id == id ? {...el, type: 'link', url: ''} : el );

    localStorage.setItem('documents', JSON.stringify(newDocuments) );
  }

  getDocument(id: number){
    const documents = this.getDocuments();

    const document = documents.find(el => el.id == id);

    return document;
  }

  //* Metodos firmas
  createSignatures(){
    const signatures: Signature[] = [
      {
        id: 1,
        title: 'Solicitud de crédito',
        link: 'nuevo-credito/documentos-credito/leer-documento/',
        type: 'view',
        source: '../../../assets/files/sample-local-pdf.pdf'
      },
      {
        id: 2,
        title: 'Pagaré',
        link: 'nuevo-credito/documentos-credito/leer-documento/',
        type: 'view',
        source: '../../../assets/files/sample-local-pdf.pdf'
      },
      {
        id: 3,
        title: 'Seguro de vida',
        link: 'nuevo-credito/documentos-credito/leer-documento/',
        type: 'view',
        source: '../../../assets/files/sample-local-pdf.pdf'
      },
      {
        id: 4,
        title: 'Autorización de descuento',
        link: 'nuevo-credito/documentos-credito/leer-documento/',
        type: 'view',
        source: '../../../assets/files/sample-local-pdf.pdf'
      },
      {
        id: 5,
        title: 'Proyección de crédito',
        link: 'nuevo-credito/documentos-credito/leer-documento/',
        type: 'view',
        source: '../../../assets/files/sample-local-pdf.pdf'
      },
      {
        id: 6,
        title: 'Autorización de consulta',
        link: 'nuevo-credito/documentos-credito/leer-documento/',
        type: 'view',
        source: '../../../assets/files/sample-local-pdf.pdf'
      }
    ]

    localStorage.setItem('signatures', JSON.stringify(signatures) );
  }

  getSignatures():Signature[] {
    return JSON.parse( localStorage.getItem('signatures') || '[]');
  }

  markSignatureAsCheck(id: number){
    const signatures = this.getSignatures();

    const newSignature = signatures.map( el => el.id == id ? {...el, type: 'check'} : el );

    localStorage.setItem('signatures', JSON.stringify(newSignature) );
  }

  markSignatureAsNotCheck(id: number){
    const signatures = this.getSignatures();

    const newSignature = signatures.map( el => el.id == id ? {...el, type: 'link'} : el );

    localStorage.setItem('signatures', JSON.stringify(newSignature) );
  }

  getSignature(id: number){
    const signatures = this.getSignatures();

    const signature = signatures.find(el => el.id == id);

    return signature;
  }
}

interface Document {
  id: number;
  title: string;
  link: string;
  type: string;
  url?: string;
}

interface Signature {
  id: number;
  title: string;
  link: string;
  type: string;
  source: string;
}
